import Vue from "vue";
import VueI18n from 'vue-i18n';

import CityAndCountryGroupedSelectMixin from './mixins/CityAndCountryGroupedSelectMixin';

const currentLocale = window.Sharewood.currentLocale;

Vue.use(VueI18n);
const i18n = new VueI18n({
    locale: currentLocale,
});

const app = new Vue({
    el: '#hero',
    i18n,
    mixins: [
        CityAndCountryGroupedSelectMixin,
    ],
    data: {
        type: null,
        macrocategory: null,
    },
    methods: {
        setHomeMenuAnimations() {
            var self = this;

            if ($(window).width() >= 768) {
                $('.choose-type').on('click', function () {
                    $('#step1').addClass('hide-block');
                    $('#step2').removeClass('hide-block');
                    $(this).addClass('active');
                    if($(this).is('#travel-choose')) {
                        $('#search-location-container').addClass('hide-searchbar');
                    } else {
                        $('#search-location-container').removeClass('hide-searchbar');
                    }
                    var currentType = $(this).data('type');
                    self.setType(currentType);
                    $('#' + currentType + '-categories').toggleClass('show-categories');
                });
                $('.categories-item').on('click', function () {
                    $('.categories-item').removeClass('selected');
                    $(this).addClass('selected');
                    var currentCategory = $(this).data('category');
                    self.setMacrocategory(currentCategory);
                });
                $('.categories-list-nav .back-to-list').on('click', function () {
                    $('.categories-item').removeClass('selected');
                    $('.categories-list').removeClass('show-categories');
                    $('.choose-type').removeClass('active');
                    $('#step2').addClass('hide-block');
                    $('#step1').removeClass('hide-block');
                    $('#search-location-container').removeClass('hide-searchbar');
                });
            } else {
                $('.choose-type').on('click', function () {
                    $('#step1').addClass('hide-block');
                    $('#step2').removeClass('hide-block');
                    $('.categories-list').removeClass('show-categories');
                    if($(this).is('#travel-choose')) {
                        $('#search-location-container').addClass('hide-searchbar');
                    } else {
                        $('#search-location-container').removeClass('hide-searchbar');
                    }
                    var currentType = $(this).data('type');
                    self.setType(currentType);
                    $('#' + currentType + '-categories').addClass('show-categories');
                });
                $('.categories-item').on('click', function () {
                    $('.categories-item').removeClass('selected');
                    $(this).addClass('selected');
                    var currentCategory = $(this).data('category');
                    self.setMacrocategory(currentCategory);
                });
                $('.categories-list .backlink').on('click', function () {
                    $('.categories-list').removeClass('show-categories');
                    $('.choose-type').show();
                });
                $('.back-to-list').on('click', function () {
                    $('.categories-item').removeClass('selected');
                    var target = $(this).data('source');
                    self.setType(null);
                    $('#' + target + '-categories').removeClass('show-categories');
                    $('#step2').addClass('hide-block');
                    $('#step1').removeClass('hide-block');
                    $('#search-location-container').removeClass('hide-searchbar');
                });
            }
        },
        setType(value) {
            this.type = value;
            this.macrocategory = null;
        },
        setMacrocategory(value) {
            this.macrocategory = value;
        },
    },
    computed: {
        _goButtonDisabled: {
            get: function(){
                return this.location == null && this.macrocategory == null;
            }
        }
    },
    mounted() {
        this.setHomeMenuAnimations();
    },
});
