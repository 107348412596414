var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "multiselect",
    {
      attrs: {
        options: _vm.options,
        "group-values": "options",
        "group-label": "label",
        label: "label",
        "track-by": "value",
        placeholder: _vm.placeholder
      },
      on: { "search-change": _vm.fetchOptions },
      model: {
        value: _vm._value,
        callback: function($$v) {
          _vm._value = $$v
        },
        expression: "_value"
      }
    },
    [
      _c("span", { attrs: { slot: "noResult" }, slot: "noResult" }, [
        _vm._v(_vm._s(_vm._no_results_hint))
      ]),
      _vm._v(" "),
      _c("span", { attrs: { slot: "noOptions" }, slot: "noOptions" }, [
        _vm._v(_vm._s(_vm._no_option_hint))
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }