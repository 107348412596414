
import CityAndCountryGroupedSelect from '../vue-components/components/select/CityAndCountryGroupedSelect';

export default {
    data(){
        return {
            location: {},
        };
    },
    components: {
        'city-and-country-grouped-select': CityAndCountryGroupedSelect,
    },
    computed: {
        _geodata_id: {
            get: function(){
                return this.location.value;
            },
        },
        _geodata_type: {
            get: function(){
                return this.location.type;
            },
        },
    },
}
