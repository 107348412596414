<template>
  <multiselect
    v-model="_value"
    :options="options"
    group-values="options"
    group-label="label"
    label="label"
    track-by="value"
    :placeholder="placeholder"
    @search-change="fetchOptions"
  >
    <span slot="noResult">{{ _no_results_hint }}</span>
    <span slot="noOptions">{{ _no_option_hint }}</span>
  </multiselect>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import Multiselect from "vue-multiselect";

export default {
  name: "CityAndCountryGroupedSelect",
  props: {
    value: {
      // type: Object,
      default: null,
    },
    placeholder: {
      type: String,
      default: "Where do you want to go?",
    },
  },
  data() {
    return {
      loading: false,
      typing: false,
      options: [],
      last_query: null,
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    fetchOptions(query) {
      this.typing = true;
      this.debounced(query);
    },
    debounced: _.debounce(function (query) {
      if (query.length >= 3 && !this.loading) {
        this.loading = true;
        this.typing = false;
        this.last_query = query;
        this.queryServer({
          search: query,
        });
      }
    }, 300),
    queryServer(customProperties) {
      const properties = {
        search: this.searchQuery,
        locale: window.Sharewood.currentLocale,
      };

      axios
        .get(
          route(
            "frontend.api.geodata.list-countries-and-cities-for-grouped-select",
            _.merge(properties, customProperties)
          )
        )
        .then((response) => {
          this.loading = false;

          const data = response.data;
          if (Array.isArray(data)) {
            this.options = [];
          } else {
            var results = [];
            if (data.cities) {
              results.push(data.cities);
            }
            if (data.aa2) {
              results.push(data.aa2);
            }
            if (data.aa1) {
              results.push(data.aa1);
            }
            if (data.countries) {
              results.push(data.countries);
            }
            this.options = results;
          }
        });
    },
  },
  computed: {
    _value: {
      get: function () {
        return this.value;
      },
      set: function (new_val) {
        if (Array.isArray(new_val)) {
          return;
        }

        this.$emit("input", new_val);
        this.$emit("change", new_val);
      },
    },
    _no_option_hint: {
      get: function () {
        if (this.last_query) {
          return window.Sharewood.translations.select.no_results;
        } else {
          return window.Sharewood.translations.select.type_to_search;
        }
      },
    },
    _no_results_hint: {
      get: function () {
        if (this.loading) {
          return window.Sharewood.translations.select.loading_text;
        }

        if (this.typing) {
          return window.Sharewood.translations.select.typing;
        }

        return window.Sharewood.translations.select.no_results;
      },
    },
  },
};
</script>
